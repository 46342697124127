import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Laravel() {
    return (
        <>
            <Banner title="Laravel Development" content="Backend Laravel development for web and mobile apps by our team of expert Laravel developers. Custom Laravel development, code takeovers, and scalable development teams." image="laravel"/>
            <Services name="Laravel Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Laravel;