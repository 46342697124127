import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer className="footer">

      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h3>Services</h3>
            <ul>
              <li><Link to="/custom-software-development">Custom Software Development</Link> </li>
              <li><Link to="/mobile-app-development">Mobile Application Development</Link> </li>
              <li><Link to="/ai">AI & Machine Learning</Link></li>
              <li><Link to="/cloud">Cloud & Infrastructure </Link></li>
              <li><Link to="/database-migration">Database Migration </Link></li>
              <li><Link to="/ui-ux">UI/UX Design </Link></li>
              <li><Link to="/qa">Quality Assurance & Testing</Link></li>
              <li><Link to="/consulting">Consulting & Strategy</Link></li>
              <li><Link to="/bpa-rpa">BPA and RPA</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3>About Us</h3>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li><Link to="/careers">Careers</Link></li>
              <li><Link to="/faqs">FAQs </Link></li>
              <li><Link to="/contact">Contact </Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3>Loactions</h3>
            <ul>
              <li>
                <b>Newport Beach:</b><br />
                23 corporate plaza Dr. suite 125 Newport Beach, CA, 92660
              </li>
              <li><b>Los Angeles:</b><br />
                9171 Wilshire Blvd Suite 500, Beverly Hills, CA 90210</li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="social-links">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/aimtechai/" target="_blank"><span className="fab fa-instagram"></span></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/aimtechai/" target="_blank"><span className="fab fa-linkedin"></span></a>
                  </li>
                 
                </ul>
            </div>
            <ul>
              <li><Link to="mailto:info@aimtechai.com"><span className="icon fa fa-envelope"></span> Info@aimtechai.com</Link></li>
              <li><Link to="tel:(310)421-8638"><span className="icon fa fa-phone"></span> (310) 421-8638</Link></li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className="copyrights">
        <p>© 2023 AIM Tech AI. All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;