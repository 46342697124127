import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Swift() {
    return (
        <>
            <Banner title="Swift Development" content="Custom iOS application development built by our team of expert Swift developers for the latest Apple devices, from iPhones to iPads to Apple Watches." image="swift"/>
            <Services name="Swift Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Swift;