import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Kotlin() {
    return (
        <>
            <Banner title="Kotlin Development" content="Custom Kotlin development by our team of expert Kotlin app developers for the latest Android devices." image="kotlin"/>
            <Services name="Kotlin Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Kotlin;