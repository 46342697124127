import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import introVideo from "../assets/videos/about.mp4";
import introVideo2 from "../assets/videos/persona-video-1.mp4";
import image1 from "../assets/images/lob.jpeg";
import image2 from "../assets/images/o.jpeg";
import image3 from "../assets/images/b1.jpeg";
import image4 from "../assets/images/b2.webp";

export const AboutVideo = () => {
  return (
    <div
      className="about-video"
      style={{ position: "relative !important", textAlign: "center" }}
    >
      <div className="container">
        <h2 style={{ color: "white" }}>
          A message from our CEO, Yasmine Kavandi:
        </h2>
        <div className="mt-4">
          <video
            className="video"
            style={{
              width: "100%",
            }}
            playsinline
            controls
          >
            <source src={introVideo} type="video/mp4" />
          </video>
        </div>
        <div className="mt-4">
          <video
            className="video"
            style={{
              width: "100%",
            }}
            playsinline
            controls
          >
            <source src={introVideo2} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export const OurOffices = () => {
  return (
    <div className="offices-section">
      <div className="container">
        <h2 style={{ color: "white", marginBottom: "20px" }}>Our Offices</h2>
        <p style={{ color: "white" }}>
          At AIM TECH AI, we understand the importance of personal touch and
          effective communication. To cater to the varied preferences of our
          prospective clients, we offer both in-person and virtual meeting
          options. For those looking to connect face-to-face, we proudly host
          meetings at our two strategic office locations: Newport Beach and Los
          Angeles. Each space is designed to facilitate productive discussions
          and foster meaningful partnerships. Whether you're closer to the
          tranquil shores of Newport Beach or the bustling heart of Los Angeles,
          our doors are always open to welcome and collaborate with you.
          Whatever your preference, rest assured that AIM TECH AI is dedicated
          to meeting you, ensuring seamless communication and a promising start
          to our partnership.
        </p>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="image">
              <img src={image1} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="image">
              <img src={image2} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="image">
              <img src={image3} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="image">
              <img src={image4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
