import Flame from '../../assets/images/flame.svg';
import Projects from '../../assets/images/projects.svg';
import Locations from '../../assets/images/locations.svg';
import Business from '../../assets/images/business.svg';
import CountUp from "react-countup";

function Counter() {
    return (
        <section className="counter-section">
            <div className="container">
                <div className="box-wrapper" data-aos="flip-up" data-aos-duration="2000">
                    <div className="item">
                        <div className="icon">
                            <img src={Projects} alt="Icon" className="img-fluid" />
                        </div>
                        
                        <h2><CountUp duration={3} end={1822} /> </h2>
                        <p>Successful Projects</p>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={Locations} alt="Icon" className="img-fluid" />
                        </div>
                        <h2><CountUp duration={1} end={2} /> </h2>
                        <p>Office Locations</p>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={Business} alt="Icon" className="img-fluid" />
                        </div>
                        <h2><CountUp duration={2} end={10} />+ </h2>
                        <p>Years In Business</p>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <img src={Flame} alt="Icon" className="img-fluid" />
                        </div>
                        <h2><CountUp duration={3} end={100} />% </h2>
                        <p>Partnership</p>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Counter;