

function Intro() {
    return (
        <section className="intro-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6" data-aos="flip-left" data-aos-duration="2000">
                        <div className="custom-box">
                            <h2>Custom Software Development</h2>
                            <p>End-to-end custom software development, driven by Subject Matter Experts from diverse industries. By merging business acumen with our technical prowess, we craft solutions that are uniquely tailored. Our expertise encompasses front-end, backend, database, and server integrations, ensuring comprehensive solutions for mobile, web, and desktop platforms.</p>
                            <a href="/contact" className="btn btn-theme">Learn more </a>
                        </div>

                    </div>
                    <div className="col-md-6" data-aos="flip-right" data-aos-duration="2000">
                        <div className="custom-box right-box">
                            <h2>Initiate a discovery call</h2>
                            <p>Let our experts meticulously guide you through the process to effectively scope your project</p>

                            <a href="/contact" className="btn btn-theme">Scope my Project</a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Intro;