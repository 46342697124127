import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function React() {
    return (
        <>
            <Banner title="React JS Development" content="React development teams for web and mobile apps. Our team of expert react developers provide front-end development services." image="react"/>
            <Services name="React JS Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default React;