

function Banner(props) {
    return (
        <>
            <section className="technologies-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content">
                                <h1>{props.title}</h1>
                                <p>{props.content}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image">
                                <img src={`/images/tech/${props.image}.jpg`} alt="Image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;