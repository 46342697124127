import { useEffect } from "react";
import PageHeader from '../components/common/pageHeader';

// import { List } from '../components/List';

const solutions_titles = ['Healthcare & Life Sciences', 'Banking & Financial Services', 'Aerospace', 'Real Estate Development & Property Management', 'Startups']


export const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <>
    <PageHeader title="Solutions" />
    {/* <div style={{ 'position': 'relative', 'paddingLeft': '40px', 'paddingRight': '40px', 'backgroundColor': 'white', 'paddingTop': '20px' }}>
      <List data={solutions_titles} />
    </div> */}

    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='services-tabs-list'>
            <ul>
              <li>
                <a href='#healthcare'>Healthcare & Life Sciences</a>
              </li>
              <li>
                <a href='#banking'>Banking & Financial Services</a>
              </li>
              <li>
                <a href='#aerospace'>Aerospace</a>
              </li>
              <li>
                <a href='#real-estate'>Real Estate Development & Property Management</a>
              </li>
              <li>
                <a href='#startups'>Startups</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='service-item' id="healthcare">
            <h3 >Healthcare &amp; Life Sciences</h3>
            <p >Navigate the complexities of the Healthcare &amp; Life Sciences
              industry with our tailored solutions. In a domain where precision
              and timely decisions are paramount, our advanced software
              applications streamline patient care, clinical trials, and medical
              data management. We harness the power of AI to drive
              diagnostics, enhance patient experiences, and optimize treatment
              plans. Our solutions also cater to pharmaceutical research,
              biotech innovations, and compliance requirements, ensuring a
              holistic approach to healthcare. Embrace digital transformation to
              provide superior patient care, advanced research, and secure
              data management in this ever-evolving sector.</p>
          </div>
          <div className='service-item' id="banking">
            <h3 >Banking &amp; Financial Services</h3>
            <p >Step into the future of Banking &amp; Financial Services with our
              innovative solutions. The financial world demands robust, secure,
              and user-friendly platforms. We specialize in developing custom
              applications that facilitate seamless transactions, risk
              management, fraud detection, and enhanced customer service
              using AI and Machine Learning. Our expertise also extends to
              asset management, trading platforms, and regulatory compliance
              solutions. In a world where digital banking and fintech are
              redefining the financial landscape, partner with us to stay ahead
              of the curve and deliver unparalleled value to your clients.</p>
          </div>
          <div className='service-item' id="aerospace">
            <h3 >Aerospace</h3>
            <p >Soar to new heights in the Aerospace industry with our cutting-
              edge solutions. Aerospace represents the pinnacle of
              technological achievement, and we are at the forefront of this
              evolution. From designing systems that optimize aircraft
              performance and fuel efficiency to leveraging AI for predictive

              maintenance and real-time analytics, our offerings are
              unparalleled. We also provide solutions for flight simulation, air
              traffic management, and supply chain optimizations. With safety,
              compliance, and efficiency as our guiding principles, we ensure
              that you remain at the zenith of aerospace innovation.</p>
          </div>
          <div className='service-item' id="real-estate">
            <h3 >Real Estate Development &amp; Property Management</h3>
            <p >Elevate your Real Estate Development &amp; Property Management
              endeavors with our bespoke solutions. In an industry that hinges
              on market insights, customer experience, and operational
              efficiency, we deliver applications that provide real-time property
              analytics, virtual tours, and tenant management systems. Our
              platforms are designed to seamlessly integrate with existing
              infrastructure, providing tools for inventory management, lease
              administration, and financial analytics. From construction project
              management to smart home integrations, we bring digitization to
              every facet of real estate, ensuring enhanced ROI and customer
              satisfaction.</p>
          </div>
          <div className='service-item' id="startups">
            <h3 >Startups</h3>
            <p >Fuel your Startup&#39;s growth trajectory with our specialized
              solutions. We understand the unique challenges startups face:
              limited resources, the need for rapid scalability, and the quest for
              market differentiation. Our agile development methodologies
              ensure quick turnarounds. We offer MVP (Minimum Viable
              Product) development to test market waters, robust cloud
              infrastructures for scalability, and AI-driven insights to understand
              user behavior. With a keen focus on innovation, user experience,
              and cost-effectiveness, we provide startups the technological
              backbone they need to disrupt the market and ascend quickly.</p>
          </div>
        </div>
      </div>
    </div>



  </>
}
