import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'

const dataf = ['item1', 'item2', 'item3']

export const List = ({data=[]}) => {
	return  <ul >
				{data.map((d, i) => {
					return <li >{d}</li>
				})}
			</ul>

}