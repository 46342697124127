import { Slideshow } from "./Slideshow";

function Banner() {
    return (
        <section className="main-banner">
            <Slideshow/>
        </section>
    );
}

export default Banner;