import { useEffect } from "react";
import { Link } from 'react-router-dom';
import PageHeader from '../components/common/pageHeader';


export const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <>

    <PageHeader title="Portfolio" />
    <div className='potfolio-page'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='content-section'>
              <h2>Our Projects - If you can dream it, we can build it.</h2>
              <p>We understand that each client has unique needs, which is why our approach is centered on merging industry-specific expertise with technological innovation to craft bespoke solutions. Our portfolio exemplifies this principle, showcasing a wide array of custom-built applications, systems, and platforms that have propelled businesses forward. For every project, we enlist subject matter experts to ensure that the end result not only meets but exceeds the distinct requirements of the sector it serves. Browse through our project history to see the concrete results of our collaborative and client-focused method — your next solution could be among them.</p>
            </div>
            <div className='porfolio-image'>
              <div className='row'>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio1.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio2.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio3.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio4.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio5.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio6.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div className='image'>
                    <img src="/images/porfolio7.jpg" alt="Project Image" className="img-fluid" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
};