import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Python() {
    return (
        <>
            <Banner title="Python Development" image="python" content="Custom Python development for frontend/backend web and application development by our expert Python programming developers." />
            <Services name="Python Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Python;