import React, { useRef, useEffect } from 'react';
import cloud from '../../assets/videos/cloud.mp4#t=0.1';

const PageHeader = ({ title = "", videoUri = "" }) => {
  // const video1Ref = useRef(null);
  // const video2Ref = useRef(null);

  // useEffect(() => {
  //   const video1 = video1Ref.current;
  //   const video2 = video2Ref.current;

  //   // Play video1 and loop it
  //   video1.play();
  //   video1.loop = true;

  //   // Play video2 when video1 ends and loop it
  //   video1.addEventListener('ended', () => {
  //     video1.style.display = 'none'; // Hide video1 when it ends
  //     video2.style.display = 'block'; // Show video2
  //     video2.play();
  //     video2.loop = true;
  //   });

  //   // Cleanup event listeners
  //   return () => {
  //     video1.removeEventListener('ended', () => { });
  //   };
  // }, []);

  return (
    <div className="page-header" >
      <video  className="page-header-video" autoPlay playsInline muted loop type="video/mp4" data-origwidth="0" data-origheight="0">
        <source src={cloud} type="video/mp4" />
      </video>

      <div className="banner-overlay">
        <div className="banner-content">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
