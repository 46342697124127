import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Angular() {
    return (
        <>
            <Banner title="Angular JS Development" content="Front-end Angular JS development for web apps, databases, portals, and more by our team of expert Angular developers. Custom built or code takeovers." image="angular" />
            <Services name="Angular JS Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Angular;