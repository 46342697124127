import { useEffect } from "react";
import PageHeader from '../../components/common/pageHeader';
// import { List } from '../components/List';
// import { Tab, Tabs } from 'react-bootstrap';




export const Cloud = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <>

    <PageHeader title="Cloud infrastructure" />

    <div className='service-detail-page'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>

            <h3 >Cloud infrastructure</h3>
            <div className="image my-3">
              <img src="/images/cloud.jpg" alt="Image" className="img-fluid" />
            </div>
            <h4>Cloud and Infrastructure Solutions at AIM Tech AI</h4>
            <p>AIM Tech AI provides comprehensive cloud and infrastructure solutions designed to streamline operations, increase scalability, and bolster your business’s technological backbone. Our strategic approach ensures that your move to the cloud is smooth, secure, and tailored to meet your specific business needs.</p>

            <h4>Expert Cloud Migration for Seamless Transitions</h4>
            <p>Our cloud services begin with a detailed migration strategy. We specialize in transferring your data, applications, and workloads to the cloud with minimal disruption. Whether it's a public, private, or hybrid cloud environment, our team ensures a seamless transition with a focus on data integrity and system compatibility.</p>
            <h4>Optimized Cloud Infrastructure for Peak Performance</h4>
            <p>We prioritize high-performance outcomes. Our cloud infrastructure services are optimized for speed, reliability, and scalability. By analyzing your business requirements, we provide solutions that can dynamically scale with your growth, ensuring cost-efficiency and enhanced performance.</p>
            <h4>Robust Security in the Cloud</h4>
            <p>Security in the cloud is paramount. AIM Tech AI implements comprehensive security measures including encryption, intrusion detection, and multi-factor authentication to safeguard your data. Our cloud security protocols are designed to meet stringent industry standards and regulations.</p>

            <h4>Modernize with Cloud-Native Technologies</h4>
            <p>Leverage the full potential of cloud-native technologies with our expert guidance. We help you adopt containerization, microservices, and serverless architectures, enabling your business to be agile and innovative while reducing overhead and increasing deployment speed.</p>
            <h4>Uninterrupted Operations with Disaster Recovery</h4>
            <p>Protect your business from unexpected events with our robust disaster recovery solutions. AIM Tech AI ensures that your data is backed up and can be quickly restored, keeping your operations running without interruption, even in the face of unforeseen disasters.</p>
            <h4>Tailored Cloud Solutions for Every Business</h4>
            <p>Every business has unique needs, and our cloud solutions are customized to address yours. From choosing the right cloud service provider to architecting a bespoke environment, our solutions are crafted to align with your business objectives.</p>
            
            <p>By partnering with AIM Tech AI for your cloud and infrastructure needs, you gain not just a service provider, but a dedicated ally in navigating the ever-evolving cloud landscape. Reach out to us to harness the full power of cloud technology and drive your business forward into the future.</p>


          </div>


        </div>
      </div>
    </div>

  </>
};