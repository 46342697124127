import { useEffect } from "react";
import PageHeader from '../components/common/pageHeader';
// import { List } from '../components/List';
// import { Tab, Tabs } from 'react-bootstrap';


export const services_titles = ['Custom Software Development', 'AI & Machine Learning', 'Mobile App Development', 'Cloud & Infrastructure', 'Database Migration', 'UI/UX Design', 'Quality Assurance & Testing', 'Consulting & Strategy', 'Robotic Process Automation (RPA)', 'Business Process Automation (BPA)']


export const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <>

    <PageHeader title="Services" />


    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='services-tabs-list'>
            <ul>
              <li>
                <a href='#custom-software-development'>Custom Software Development</a>
              </li>
              <li>
                <a href='#ai'>AI & Machine Learning</a>
              </li>
              <li>
                <a href='#mobile-development'>Mobile App Development</a>
              </li>
              <li>
                <a href='#cloud'>Cloud & Infrastructure</a>
              </li>
              <li>
                <a href='#database'>Database Migration</a>
              </li>
              <li>
                <a href='#ui-ux'>UI/UX Design</a>
              </li>
              <li>
                <a href='#qa'>Quality Assurance & Testing</a>
              </li>
              <li>
                <a href='#consulting'>Consulting & Strategy</a>
              </li>
              <li>
                <a href='#rpa'>Robotic Process Automation (RPA)</a>
              </li>
              <li>
                <a href='#bpa'>Business Process Automation (BPA)</a>
              </li>
            </ul>
            {/* <List data={services_titles} /> */}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='service-item' id="custom-software-development">
            <h3 >Custom Software Development</h3>
            <p >Unlock unparalleled business efficiency with our Custom Software
              Development services. In today&#39;s digitized marketplace, a one-
              size-fits-all approach often falls short. Our dedicated team of
              software engineers and developers work hand-in-hand with
              clients to design, develop, and deploy tailor-made applications.
              These solutions, whether web-based, desktop, or hybrid, are
              specifically created to address your unique business challenges.
              From integrating with existing systems to leveraging modern
              frameworks and architectures, we ensure a blend of innovation
              and functionality, setting your business apart in a competitive
              landscape.</p>
          </div>

          <div className='service-item' id="ai">
            <h3 >AI and Machine Learning</h3>
            <p >Venture into the next frontier of technological innovation with our
              AI &amp; Machine Learning solutions. Today&#39;s market demands
              predictive insights, automation, and intelligent interfaces. By
              leveraging sophisticated algorithms, neural networks, and data
              analytics, we craft solutions that enable businesses to tap into
              unseen opportunities. Be it natural language processing for
              enhanced customer service, computer vision for product
              enhancements, or predictive analytics for informed decision-
              making, we ensure you stay ahead with AI-driven insights.</p>
          </div>

          <div className='service-item' id="cloud">
            <h3 >Cloud and Infrastructure:</h3>
            <p >Empower your business with the scalability and flexibility of
              contemporary Cloud &amp; Infrastructure services. As digital
              ecosystems expand, the need for reliable, scalable, and secure
              cloud infrastructures is paramount. We offer solutions spanning
              public, private, and hybrid clouds, leveraging platforms like AWS,
              Azure, and Google Cloud. Our cloud solutions ensure seamless
              data access, high availability, and cost-effective infrastructures
              that drive business growth.</p>
          </div>

          <div className='service-item' id="database">
            <h3 >Database Migration</h3>
            <p >Master the art of data adaptability with our Database Migration
              services. In the era of Big Data, shifting between storage
              solutions, migrating legacy systems, or upgrading to the latest
              database technologies can be daunting. Our experts proficient in
              SQL, NoSQL, and various database platforms ensure that data
              migration is seamless, lossless, and efficient. We prioritize data
              integrity, security, and compliance, ensuring your databases
              remain robust and ready for future innovations.</p>
          </div>

          <div className='service-item' id="ui-ux">
            <h3 >UI/UX Design</h3>
            <p >Craft unforgettable digital experiences with our UI/UX Design
              expertise. The interface and user experience define a brand&#39;s
              digital identity. Our designers, grounded in the latest design
              principles and trends, employ wireframing, prototyping, and
              interactive designs to create interfaces that enchant and engage.
              By focusing on user-centric designs, accessibility standards, and
              responsive layouts, we ensure your users enjoy a seamless
              journey across devices and platforms.</p>
          </div>

          <div className='service-item' id="qa">
            <h3 >Quality Assurance &amp; Testing</h3>
            <p >Commit to software excellence with our comprehensive Quality
              Assurance &amp; Testing services. In the digital age, the margin for
              error is minimal. Our QA specialists employ a combination of
              manual testing, automated test scripts, performance testing, and
              security audits to ensure your software stands tall under any
              condition. Using tools like Selenium, JIRA, and LoadRunner, we
              ensure a holistic QA process that guarantees software reliability
              and robustness.</p>
          </div>

          <div className='service-item' id="consulting">
            <h3 >Consulting &amp; Strategy:</h3>
            <p >Steer your digital transformation with precision using our
              Consulting &amp; Strategy services. In an evolving technological
              landscape, direction and foresight are crucial. Our seasoned
              consultants leverage industry insights, market analytics, and
              technological expertise to craft bespoke strategies for your
              business. Whether it&#39;s digital roadmapping, tech stack
              recommendations, or ROI optimization, we guide businesses
              toward a prosperous digital future.</p>
          </div>

          <div className='service-item' id="rpa">
            <h3 >Robotic Process Automation (RPA)</h3>
            <p >In today's fast-paced business environment, efficiency and precision are paramount. AIM TECH AI's Robotic Process Automation (RPA) solutions are designed to meet these challenges head-on. We deploy intelligent software bots to automate repetitive and routine tasks, ensuring that operations run smoothly and errors are minimized. From data extraction and processing to integrating with various IT applications, our RPA solutions are versatile and customizable to specific business needs. This not only accelerates processes but also liberates your workforce, enabling them to focus on more value-added activities. With AIM TECH AI's RPA services, expect a transformative boost in productivity, enhanced accuracy, and substantial cost savings.</p>
          </div>

          <div className='service-item' id="bpa">
            <h3 >Business Process Automation (BPA)</h3>
            <p >AIM TECH AI recognizes that every enterprise, irrespective of its size, seeks optimization in its business processes. Our Business Process Automation (BPA) services are crafted to seamlessly streamline and automate your workflows. We delve deep into understanding the intricacies of your operations, identifying areas ripe for automation. Leveraging advanced technologies and tools, our BPA solutions ensure consistent and efficient processes, reduced manual intervention, and real-time monitoring. Whether it's automating complex business workflows, integrating systems and platforms, or enhancing data accessibility and sharing, AIM TECH AI's BPA services are all about optimizing business performance and driving tangible outcomes. Experience agility, reduced operational costs, and improved service delivery with our bespoke BPA solutions.</p>
          </div>

          <div className='service-item' id="mobile-development">
            <h3>Mobile App Development</h3>
            <p >AIM TECH AI recognizes that every enterprise, irrespective of its size, seeks optimization in its business processes. Our Business Process Automation (BPA) services are crafted to seamlessly streamline and automate your workflows. We delve deep into understanding the intricacies of your operations, identifying areas ripe for automation. Leveraging advanced technologies and tools, our BPA solutions ensure consistent and efficient processes, reduced manual intervention, and real-time monitoring. Whether it's automating complex business workflows, integrating systems and platforms, or enhancing data accessibility and sharing, AIM TECH AI's BPA services are all about optimizing business performance and driving tangible outcomes. Experience agility, reduced operational costs, and improved service delivery with our bespoke BPA solutions.</p>
          </div>
        </div>
      </div>
    </div>

  </>
};