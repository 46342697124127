import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import cloud from "../../assets/videos/cloud.mp4#t=0.1";
import earth from "../../assets/videos/earth.mp4#t=0.1";
import { Link } from "react-router-dom";

const spanStyle = {
  padding: "20px",
  background: "#efefef",
  color: "#000000",
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  minHeight: "500px",
};
const slideImages = [
  {
    url: "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    videoUrl: earth,
    caption: "Custom Software Solutions",
  },
  {
    videoUrl: cloud,
    caption: "Custom Software Solutions",
  },
];

export const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index} className="slider-item">
            <div className="banner-overlay">
              <div className="banner-content">
                <h3 style={{ color: "rgb(15, 193, 183)" }}>AIM Tech AI</h3>
                <h2 style={{ color: "white" }}>{slideImage.caption}</h2>
                <Link to="/about" className="btn btn-theme">
                  Watch Video
                </Link>
              </div>
            </div>
            <video
              className="video"
              autoPlay
              playsInline
              muted
              loop
              type="video/mp4"
              data-origwidth="0"
              data-origheight="0"
            >
              <source src={slideImage.videoUrl} type="video/mp4" />
            </video>
          </div>
        ))}
      </Slide>
      {/* <div className='banner-overlay'>
        <div className="banner-content">
          <h3 style={{ 'color': 'rgb(15, 193, 183)' }}>AIM Tech AI</h3>
          <h2 style={{ 'color': 'white' }}>Custom Software Solutions</h2>
        </div>
      </div> */}
    </div>
  );
};
