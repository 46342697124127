import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Django() {
    return (
        <>
            <Banner title="Django Development" content="Custom Django development for frontend/backend web and application development by our expert Django programming developers." image="django"/>
            <Services name="Django Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Django;