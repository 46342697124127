import Flame from '../../assets/images/flame.svg';
import Projects from '../../assets/images/projects.svg';
import Locations from '../../assets/images/locations.svg';
import Business from '../../assets/images/business.svg';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';

function Portfolio() {
    return (
        <section className="portfolio-section">
            <div className="container">
                <div className='row justify-content-md-center'>
                    <div className='col-md-8' data-aos="flip-down" data-aos-duration="2000">
                        <div className='custom-box'>
                            <h2>Explore Our Portfolio</h2>
                            <p>Discover the depth of our expertise. Visit our portfolio to see how we bring together subject matter experts and cutting-edge technology to create tailored solutions for our clients. Each project is a showcase of our dedication to merging business insights with tech innovation.</p>
                            <Link to="/portfolio" className='btn btn-theme'>View Our Portfolio</Link>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Portfolio;