import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import validate from "../utils/authFormValidation";
import PageHeader from "../components/common/pageHeader";
import ContactUs from "../components/contactUs";

export const Contact = () => {
  const initialFormData = {
    name: "",
    email: "",
    description: "",
  };

  const [validationErrors, setValidationErrors] = useState({});
  const [form, updateForm] = useState({ ...initialFormData });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function updateFormHandler(name, value) {
    updateForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function inputChangedHandler(event) {
    // Get name of changed input, and its corresponding value
    const { name, value } = event.target;

    // Update form state against the target input field
    updateFormHandler(name, value);
  }

  async function submitContact(e) {
    const errors = validate(form);

    if (Object.keys(errors).length > 0) {
      setValidationErrors({ ...errors });
      return;
    } else {
      setValidationErrors({});
    }
  }

  return (
    <>
      <PageHeader title="Contact Us" />
      <section className="hirng-steps">
        <div className="container">
          <h2>Getting Started</h2>
          <div className="steps">
            <div className="item">
              <div className="icon">
                <img src="/images/step1.svg" alt="Icon" className="img-fluid" />
              </div>
              <div className="conatent">
                <h4>Step 1</h4>
                <p>Contact Us</p>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src="/images/step1.svg" alt="Icon" className="img-fluid" />
              </div>
              <div className="conatent">
                <h4>Step 2</h4>
                <p>No Cost Consulting</p>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <img src="/images/step1.svg" alt="Icon" className="img-fluid" />
              </div>
              <div className="conatent">
                <h4>Step 3</h4>
                <p>Proposal</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-page">
        <div className="container">
          <h2>Contact Us</h2>
          <div className="row">
            <div className="col-md-6">
              <ContactUs />
            </div>
            <div className="col-md-6">
              <div className="contact-info">
                <ul>
                  <li>
                    <Link to="mailto:info@aimtechai.com">
                      <span className="icon fa fa-envelope"></span>{" "}
                      Info@aimtechai.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:(310)421-8638">
                      <span className="icon fa fa-phone"></span> (310) 421-8638
                    </Link>
                  </li>
                </ul>
                <h3>Loactions</h3>
                <ul>
                  <li>
                    <b>Newport Beach:</b>
                    <br />
                    23 corporate plaza Dr. suite 125 Newport Beach, CA, 92660
                  </li>
                  <li>
                    <b>Los Angeles:</b>
                    <br />
                    9171 Wilshire Blvd Suite 500, Beverly Hills, CA 90210
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
