const VALID_EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALID_PASSWORD_PATTERN = /((?=.*\d)(?=.*[a-z])(?=.*[\W]).{8,64})/;
const DOMAIN_PATTERN =
  /^([a-z0-9A-Z]\.)*[a-z0-9-]+\.([a-z0-9]{2,24})+(\.co\.([a-z0-9]{2,24})|\.([a-z0-9]{2,24}))*$/i;
const NAME_PATTERN = /^[a-zA-Z\s]*$/;
const TEXT_PATTERN = /^\s*$/;
const POSITIVE_NUMBER = /^[1-9]+[0-9]*$/;

export default function validate(form) {
  const errors = {};

  for (const key in form) {
    if (form.hasOwnProperty(key) && !form[key]) {
      errors[key] = `${key} is required`;
    }
  }

  if ("name" in form && !form.name) {
    errors.name = "Name is required";
  }

  if ("name" in form && !form.name) {
    errors.name = "Name is required";
  }

  if ("teamNo" in form && !form.teamNo) {
    errors.teamNo = "TeamNo is required";
  }

  if ("teamName" in form && !form.teamName) {
    errors.teamName = "Team Name is required";
  }

  if ("groups" in form && !form.groups) {
    errors.groups = "Groups is required";
  }

  if ("skins" in form && !form.skins) {
    errors.skins = "Skins is required";
  }

  if ("correctCheckInCodeMessage" in form && !form.correctCheckInCodeMessage) {
    errors.correctCheckInCodeMessage =
      "Correct CheckIn Code Message is required";
  }

  if (
    "inCorrectCheckInCodeMessage" in form &&
    !form.inCorrectCheckInCodeMessage
  ) {
    errors.inCorrectCheckInCodeMessage =
      "In Correct CheckIn Code Message is required";
  }

  if (
    "correctCheckOutCodeMessage" in form &&
    !form.correctCheckOutCodeMessage
  ) {
    errors.correctCheckOutCodeMessage =
      "Correct CheckOutCode Message is required";
  }

  if (
    "inCorrectCheckOutCodeMessage" in form &&
    !form.inCorrectCheckOutCodeMessage
  ) {
    errors.inCorrectCheckOutCodeMessage =
      "In Correct CheckOutCode Message is required";
  }

  if ("domain" in form && !form.domain) {
    errors.domain = "Domain is required";
  }

  if ("teamNo" in form && !form.teamNo) {
    errors.teamNo = "Team No  is required";
  }

  if ("avatar" in form && !form.avatar) {
    errors.avatar = "Avatar name is required";
  }

  if ("color" in form && !form.color) {
    errors.color = "Color name is required";
  }

  if ("intro_video" in form && !form.intro_video) {
    errors.intro_video = "Introductory Video is required";
  }

  if ("price" in form && !form.price) {
    errors.price = "Price is required";
  }

  if ("stripe_Pricing_Id" in form && !form.stripe_Pricing_Id) {
    errors.stripe_Pricing_Id = "Price ID is required";
  }

  if ("promoCode" in form && !form.promoCode) {
    errors.promoCode = "promoCode is required";
  }

  if ("expertise_video" in form && !form.expertise_video) {
    errors.expertise_video = "Expertise Video is required";
  }

  if ("terms" in form && form.terms === false) {
    errors.terms = "you must agree terms and condition to register an account ";
  }

  if ("firstName" in form) {
    if (!form.firstName) {
      errors.firstName = "First Name is required";
    } else if (!NAME_PATTERN.test(form.firstName)) {
      errors.firstName =
        "First Name seems to be invalid. Only English alphabets are allowed in name.";
    } else if (form.firstName.trim().length < 2) {
      errors.firstName = "First Name should have at least 2 characters.";
    } else if (form.firstName.trim().length > 50) {
      errors.firstName = "First Name should be lesser than 50 characters.";
    }
  }

  if ("lastName" in form) {
    if (!form.lastName) {
      errors.lastName = "Last Name is required";
    } else if (!NAME_PATTERN.test(form.lastName)) {
      errors.lastName =
        "Last Name seems to be invalid. Only English alphabets are allowed in name.";
    } else if (form.lastName.trim().length < 2) {
      errors.lastName = "Last Name should have at least 2 characters.";
    } else if (form.lastName.trim().length > 50) {
      errors.lastName = "Last Name should be lesser than 50 characters.";
    }
  }

  if ("gender" in form && !form.gender) {
    errors.gender = "Gender name is required";
  }

  if ("country" in form && !form.country) {
    errors.country = "Country name is required";
  }

  if ("states" in form && !form.states) {
    errors.states = "States name is required";
  }

  if ("jobType" in form && !form.jobType) {
    errors.jobType = "Job Type name is required !";
  }

  if ("jobCategory" in form && !form.jobCategory) {
    errors.jobCategory = "Job Category name is required !";
  }

  if ("description" in form && !form.description) {
    errors.description = "Description is required !";
  }

  if ("address" in form && !form.address) {
    errors.address = "Address is required !";
  }

  // if ("minpay" in form && !form.minpay) {
  //   errors.minpay = "Mini Pay is required !";
  // }

  // if ("maxpay" in form && !form.maxpay) {
  //   errors.maxpay = "Maxpay Pay is required !";
  // }

  // if ("maxpay" in form && !form.maxpay) {
  //   errors.maxpay = "Maxpay Pay is required !";
  // }

  if ("authState" in form && !form.authState) {
    errors.authState = "Auth State name is required";
  }

  if ("companyDomain" in form) {
    if (!form.companyDomain) {
      errors.companyDomain = "Company domain is required";
    } else if (!DOMAIN_PATTERN.test(form.companyDomain)) {
      errors.companyDomain = "Domain address is invalid";
    }
  }

  if ("industry" in form && !form.industry) {
    errors.industry = "Industry is required";
  }

  if ("noEmployee" in form && !form.noEmployee) {
    errors.noEmployee = "Company size is required";
  }

  if ("selectedDeptHead" in form && !form.selectedDeptHead) {
    errors.selectedDeptHead = "Please select a department head";
  }

  if ("userName" in form) {
    if (!form.userName) {
      errors.userName = "Name is required";
    } else if (!NAME_PATTERN.test(form.userName)) {
      errors.userName =
        "Name seems to be invalid. Only English alphabets are allowed in name.";
    } else if (form.userName.trim().length < 2) {
      errors.userName = "Name should have at least 2 characters.";
    } else if (form.userName.trim().length > 50) {
      errors.userName = "Name should be lesser than 50 characters.";
    }
  }

  if ("jobTitle" in form) {
    if (!form.jobTitle) {
      errors.jobTitle = "Job Title is required";
    } else if (!NAME_PATTERN.test(form.jobTitle)) {
      errors.jobTitle =
        "Job Title seems to be invalid. Only English alphabets are allowed in name.";
    } else if (form.jobTitle.trim().length < 2) {
      errors.jobTitle = "Job Title should have at least 2 characters.";
    } else if (form.jobTitle.trim().length > 50) {
      errors.jobTitle = "Job Title should be lesser than 50 characters.";
    }
  }

  if ("email" in form) {
    if (!form.email) {
      errors.email = "Email address is required";
    } else if (!VALID_EMAIL_PATTERN.test(form.email)) {
      errors.email = "Email address is invalid";
    }
  }

  if ("password" in form) {
    if (!form.password) {
      errors.password = "Password is required";
    } else if (
      "confirmPassword" in form &&
      !VALID_PASSWORD_PATTERN.test(form.password)
    ) {
      errors.password =
        "Password must be 8 or more characters long, must contain alphabet [a-z] & number [0-9] & special character";
    }
  }

  if ("confirmPassword" in form) {
    if (!form.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (form.password !== form.confirmPassword) {
      errors.confirmPassword = "Password fields do not match";
    }
  }

  if ("currentPassword" in form && !form.currentPassword) {
    errors.currentPassword = "Current Password is required";
  }

  if ("type" in form && !form.type) {
    errors.type = "Designation is required";
  }

  if ("reporterId" in form && !form.reporterId) {
    errors.reporterId = "Reporting to is required";
  }

  if ("publicApiKey" in form && !form.publicApiKey) {
    errors.publicApiKey = "Public Key  is required";
  }

  if ("privateApiKey" in form && !form.privateApiKey) {
    errors.privateApiKey = "Private Key is required";
  }

  if ("title" in form) {
    if (!form.title) {
      errors.title = "Title is required";
    } else if (TEXT_PATTERN.test(form.title) || !form.title.trim().length) {
      errors.title = "Title seems to be invalid.";
    } else if (form.title.length > 140) {
      errors.title = "Title should be lesser than 140 characters.";
    }
  }

  if ("departmentName" in form) {
    if (!form.departmentName) {
      errors.departmentName = "Department Name is required";
    } else if (
      TEXT_PATTERN.test(form.title) ||
      !form.departmentName.trim().length
    ) {
      errors.departmentName = "Department Name seems to be invalid.";
    } else if (form.departmentName.length > 30) {
      errors.departmentName =
        "Department Name should be lesser than 30 characters.";
    }
  }

  if ("prompt" in form) {
    if (!form.prompt) {
      errors.prompt = "Prompt is required";
    }
    // else if (getCount(form.prompt) > 15) {
    //   errors.prompt =
    //     "Prompt Should be lesser than 15 characters.";
    // }
  }

  if ("selectedDepartment" in form) {
    if (
      !form.selectedDepartment ||
      (Array.isArray(form.selectedDepartment) &&
        form.selectedDepartment.length === 0) ||
      Object.keys(form.selectedDepartment).length === 0
    ) {
      errors.selectedDepartment = "Department is required";
    }
  }

  if ("selectedEmployee" in form) {
    if (
      !form.selectedEmployee ||
      (Array.isArray(form.selectedEmployee) &&
        form.selectedEmployee.length === 0) ||
      Object.keys(form.selectedEmployee).length === 0
    ) {
      errors.selectedEmployee = "Employee is required";
    }
  }

  if ("assignDepartment" in form) {
    if (
      !form.assignDepartment ||
      (Array.isArray(form.assignDepartment) &&
        form.assignDepartment.length === 0) ||
      Object.keys(form.assignDepartment).length === 0
    ) {
      errors.assignDepartment = "Department is required";
    }
  }

  if ("permission" in form) {
    if (
      !form.permission ||
      (Array.isArray(form.permission) && form.permission.length === 0) ||
      Object.keys(form.permission).length === 0
    ) {
      errors.permission = "Permission is required";
    }
  }

  if ("expiryDate" in form && !form.expiryDate) {
    errors.expiryDate = "Expiry Date is required";
  }

  if ("scoreTarget" in form && !form.scoreTarget) {
    errors.scoreTarget = "Score Target is required";
  }

  if ("scoreTarget" in form && !form.scoreTarget) {
    errors.scoreTarget = "Score Target is required";
  }

  if ("employeeId" in form && !form.employeeId) {
    errors.employeeId = "Employee is required";
  }

  if ("portfolioId" in form && !form.portfolioId) {
    errors.portfolioId = "Portfolio is required";
  }

  if ("goalId" in form && !form.goalId) {
    errors.goalId = "Goal is required";
  }

  if ("rewardType" in form && !form.rewardType) {
    errors.rewardType = "Reward Type is required";
  }

  if ("rewardValue" in form) {
    if (!form.rewardValue) {
      errors.rewardValue = "Reward Value is required";
    } else if (!POSITIVE_NUMBER.test(form.rewardValue)) {
      errors.rewardValue =
        "Reward Value seems to be invalid. Only Positive Number are allowed";
    }
  }

  if ("criteria" in form) {
    if (!form.criteria) {
      errors.criteria = "Reward Criteria is required";
    } else if (
      TEXT_PATTERN.test(form.criteria) ||
      !form.criteria.trim().length
    ) {
      errors.criteria = "Reward Criteria seems to be invalid.";
    } else if (form.criteria.length > 500) {
      errors.criteria = "Reward Criteria should be lesser than 500 characters.";
    }
  }

  if ("shop" in form && !form.shop) {
    errors.shop = "Shop name is required";
  }

  if ("startDate" in form && !form.startDate) {
    errors.startDate = "Start Date is required";
  } else if (form.type === "portfolio") {
    errors.startDate = "Start Date cannot be less than the current date";
  }

  if ("endDate" in form && !form.endDate) {
    errors.endDate = "End Date is required";
  } else if ("startDate" in form) {
    errors.endDate =
      "End Date should be greater than or equal to the Start Date";
  }

  if ("message" in form) {
    if (!form.message) {
      errors.message = "Description is required";
    } else if (TEXT_PATTERN.test(form.message) || !form.message.trim().length) {
      errors.message = "Message seems to be invalid.";
    } else if (form.message.length > 500) {
      errors.message = "Message should be lesser than 500 characters.";
    }
  }

  if ("app" in form && !form.app) {
    errors.app = "App is required";
  }

  if ("kpi" in form && !form.kpi) {
    errors.kpi = "KPI metric is required";
  }

  if ("targetValue" in form && !form.targetValue) {
    errors.targetValue = "Target is required";
  }

  if ("KPIData" in form && form.KPIData.length === 0) {
    errors.KPIData = "KPI's data is required";
  }

  return errors;
}

function getCount(str) {
  return str.split(" ").filter(function (num) {
    return num !== "";
  }).length;
}
