import car from '../../assets/videos/car.mp4#t=0.1';
import Logo1 from '../../assets/images/Picture1.png';
import Logo2 from '../../assets/images/Picture2.png';
import Logo3 from '../../assets/images/Picture3.png';
import Logo4 from '../../assets/images/Picture4.png';
import Logo5 from '../../assets/images/Picture5.png';
import Logo6 from '../../assets/images/Picture6.png';
import Logo7 from '../../assets/images/Picture7.png';
import Logo8 from '../../assets/images/Picture8.png';
import Slider from "react-slick";

function Trusted() {
    const settings = {
        dots: false,
        arrows:false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
          ]
    };
    return (
        <section className="trusted-section">
            <div className='logo-slider-area'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>TRUSTED BY LEADING BRANDS</h2>
                            <Slider {...settings} className='logo-slider'>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo1} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo2} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo3} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo4} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo5} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo6} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo7} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='image'>
                                        <img src={Logo8} alt="logo" className='img-fluid' />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video">
                <video playsInline autoPlay muted loop type="video/mp4" data-origwidth="0" data-origheight="0">
                    <source src={car} type="video/mp4" />
                </video>
            </div>
        </section >
    );
}

export default Trusted;