import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Lamp() {
    return (
        <>
            <Banner title="L.A.M.P. Development" content="Custom Linux, Apache, MySQL, and PHP development for dynamic web apps by our team of expert L.A.M.P. developers." image="lamp"/>
            <Services name="L.A.M.P. Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Lamp;