import { useEffect } from "react";

import PageHeader from "../components/common/pageHeader";

export const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeader title="Frequently Asked Questions (FAQs)" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                1. What services does AIM Tech AI offer?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                We specialize in custom software development, AI &amp; machine
                learning services, cloud infrastructure, UI/UX design, quality
                assurance, and strategic IT consulting. For a detailed overview,
                please visit our Services page.
              </p>
            </div>
            {/* <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                2. How long have you been in business?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                AIM Tech AI is serving clients with dedication and expertise for
                over two decades.
              </p>
            </div> */}
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                2. What industries do you typically serve?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                We cater to a diverse range of industries, including finance,
                healthcare, e-commerce, education, and more. Our team has the
                expertise to handle unique challenges across different sectors.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                3. How does your project development process work?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                Our process starts with understanding your requirements,
                followed by design, development, testing, and deployment.
                Throughout the project, we emphasize transparent communication
                to ensure your vision is realized accurately.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                4. What technologies do you work with?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                We work with a wide range of modern technologies, including but
                not limited to Python, JavaScript, React, TensorFlow, AWS,
                Azure, and more. Our technical stack is tailored to the
                project's needs.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                5. How do you ensure the quality of your projects?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                Quality is paramount to us. We employ rigorous testing, both
                manual and automated, at various stages of the project. Our QA
                team ensures that the final product meets the highest standards.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                6. Can you provide references or case studies from previous
                projects?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                Certainly! Please visit our Portfolio page to view some of our
                standout projects. References can be provided upon request.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                7. How do you handle project timelines and budgets?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                Our mission is to come in under budget and deliver projects
                promptly. We prioritize open communication, regular updates, and
                use project management tools to ensure timely delivery.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                8. How do you handle data security and confidentiality?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                Data security is crucial for us. We adhere to best practices and
                industry standards, ensuring all data is encrypted, stored, and
                transmitted securely. All our employees also sign
                confidentiality agreements.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4 style={{ padding: "8px 10px" }}>
                9. I'm interested in joining your team. How can I apply?
              </h4>
              <p style={{ padding: "8px 10px" }}>
                We're always eager to meet talented individuals. Please visit
                our Careers page, attach your resume, and share a brief message
                about your expertise. We'll get back to you if there's a
                suitable opening.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
