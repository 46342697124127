import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function C() {
    return (
        <>
            <Banner title="C / C# / C++" content="Custom C / C# / C++ development for backend application development by our expert C programming developers." image="c"/>
            <Services name="C / C# / C++" />
            <Hiring />
            <Contact />
        </>
    );
}

export default C;