import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  minHeight:'500px'
}
const slideImages = [
  {
    testimonial: "Partnering with AIM Tech AI has been one of the most transformative decisions for our business. Their deep understanding of our industry, combined with cutting-edge tech solutions, has streamlined our operations and elevated our tenant experiences. From real-time property analytics to efficient tenant management systems, their team has consistently delivered beyond our expectations. In a field where precision, timeliness, and customer satisfaction are crucial, AIM Tech AI has proven time and again that they are not just our service providers but genuine partners in our growth. We couldn't ask for a better technical arm to navigate the future of property management",
    author:'Debra Lezano - Chief Technology Officer'
  },
  {
    testimonial: "In the dynamic world of healthcare, where patient care, medical research, and compliance converge, it's paramount to have a technology partner that understands the intricacies and nuances of our field. Since our collaboration began with AIM Tech AI , we've experienced nothing short of excellence. Their team seamlessly integrated with ours, understanding our challenges in-depth, from patient data management to streamlining clinical processes. Their AI-driven solutions have been revolutionary, offering predictive diagnostics and enhancing patient experiences in ways we hadn't imagined. With the rapid evolution of medical technology and the constant need for innovation, AIM Tech AI has been our anchor, ensuring that we stay at the cutting edge while maintaining the human touch so essential in our industry. Their dedication, expertise, and genuine commitment to our mission have solidified them as an invaluable extension of our healthcare team.",
    author:'Dr. Ronald Vacarro - Director of Operations'
  },
  {
    testimonial: "Our collaboration with AIM Tech AI has been a game-changer. Their team's nuanced understanding of the financial sector, complemented by their tech prowess, has equipped us to navigate challenges, from fraud detection to asset management and beyond. Their custom applications have not only optimized our transaction processes but have also revolutionized our customer interaction, making financial management more seamless and user-friendly. As we stand at the crossroads of traditional banking and the digital future, it's reassuring to have AIM Tech AI by our side, translating our vision into tangible, efficient solutions. Their commitment to innovation and reliability mirrors our own values, making this partnership not just strategic but truly symbiotic.",
    author:'Patrick Reyes - VP of Operations'
  },
];

export const TestimonialSlideshow = () => {
    return (
      <section className='testimonial-section'>
      <div className="slide-container">
        <h1 style={{'color':'white'}}>Testimonials</h1>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index} style={{'position': 'relative',
                                      'overflow': 'hidden',
                                      'padding':'40px','padding-bottom':'0'}}>
              <p style={{'color':'white'}}>{slideImage.testimonial}</p>
              <h4 style={{'color':'rgb(15, 193, 183)'}}>{slideImage.author}</h4>
            </div>
          ))} 
        </Slide>
      </div>
      </section>
    )
}

