import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Unity() {
    return (
        <>
            <Banner title="Unity Development" content="Custom 3D application development across mobile, web, virtual reality, augmented reality and desktop. Solutions for business, education, training, and sales." image="unity"/>
            <Services name="Unity Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Unity;