

function Hiring() {
    return (
        <>
         <section className="hirng-steps">
                <div className="container">
                    <h2>Getting Started</h2>
                    <div className="steps">
                        <div className="item">
                            <div className="icon"><img src="/images/step1.svg" alt="Icon" className="img-fluid" /></div>
                            <div className="conatent">
                                <h4>Step 1</h4>
                                <p>Contact Us</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon"><img src="/images/step1.svg" alt="Icon" className="img-fluid" /></div>
                            <div className="conatent">
                                <h4>Step 2</h4>
                                <p>No Cost Consulting</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon"><img src="/images/step1.svg" alt="Icon" className="img-fluid" /></div>
                            <div className="conatent">
                                <h4>Step 3</h4>
                                <p>Proposal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hiring;