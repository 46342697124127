import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoIcon from '../../assets/images/aim_transparent_logo.png';
import logoText from '../../assets/images/aim_logo_text.jpg';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function Header() {

    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("collapse show");
        console.log(_opened);
        if (_opened === true && !clickover.hasClass("navbar-toggler") && !clickover.hasClass("dropdown-toggle") ) {
            $("button.navbar-toggler").click();
        }
    });

    return (
        <header className='header'>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/"><img src={logoIcon} style={{ 'width': '50px', 'height': '50px' }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Link className="nav-link" to="/">Home</Link>
                            <NavDropdown title="Services" id="basic-nav-dropdown">
                                {/* <Link className='dropdown-item' to="/services">All Services</Link> */}
                                <Link className='dropdown-item' to="/custom-software-development">Custom Software Development</Link>
                                <Link className='dropdown-item' to="/mobile-app-development">Mobile Application Development</Link>
                                <Link className='dropdown-item' to="/ai">AI & Machine Learning</Link>
                                <Link className='dropdown-item' to="/cloud">Cloud & Infrastructure </Link>
                                <Link className='dropdown-item' to="/database-migration">Database Migration </Link>
                                <Link className='dropdown-item' to="/ui-ux">UI/UX Design </Link>
                                <Link className='dropdown-item' to="/qa">Quality Assurance & Testing</Link>
                                <Link className='dropdown-item' to="/consulting">Consulting & Strategy</Link>
                                <Link className='dropdown-item' to="/bpa-rpa">RPA and BPA</Link>
                            </NavDropdown>
                            <NavDropdown title="Technologies" id="basic-nav-dropdown">
                                {/* <a className='dropdown-item' href="/solutions">Solutions </a> */}
                                <Link className='dropdown-item' to="/python-development/">Python Development</Link>
                                <Link className='dropdown-item' to="/net-development/">.NET Development </Link>
                                <Link className='dropdown-item' to="/php-development/">PHP Development </Link>
                                <Link className='dropdown-item' to="/react-js-development/">React.JS Development</Link>
                                <Link className='dropdown-item' to="/angular-js-development/">Angular.JS Development</Link>
                                <Link className='dropdown-item' to="/kotlin-development/">Kotlin Development</Link>
                                <Link className='dropdown-item' to="/swift-development/">Swift Development</Link>
                                <Link className='dropdown-item' to="/c-development/">C / C# / C++</Link>
                                <Link className='dropdown-item' to="/lamp-development/">L.A.M.P. Development</Link>
                                <Link className='dropdown-item' to="/unity-development/">Unity Development</Link>
                                <Link className='dropdown-item' to="/laravel-development/">Laravel Development</Link>
                                <Link className='dropdown-item' to="/django-development/">Django Development</Link>
                            </NavDropdown>

                            <Link className="nav-link" to="/portfolio">Portfolio</Link>
                            <Link className="nav-link" to="/about">About</Link>
                            <Link className="nav-link" to="/careers">Careers</Link>
                            <Link className="nav-link" to="/faqs">FAQs</Link>
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;