import { useEffect } from "react";

import { AboutVideo, OurOffices } from "../components/AboutVideo";
import PageHeader from "../components/common/pageHeader";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeader title="About" />
      <div className="about-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div style={{ marginTop: "20px" }}>
                <h2 style={{ padding: "8px 10px" }}>About Us</h2>
                <p style={{ padding: "8px 10px" }}>
                  AIM Tech AI has carved a niche for itself in the technology
                  consulting domain by persistently delivering custom software
                  applications, innovative AI solutions, and comprehensive
                  digital strategies. Our expansive range of services, from web
                  and mobile application development to cloud infrastructure and
                  machine learning, is a testament to our deep- rooted
                  commitment to the world of technology.
                </p>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p style={{ padding: "8px 10px" }}>
                  At the heart of our endeavors lies a resolute mission: to
                  consistently come in under budget and expedite project
                  deliveries without compromising quality. We believe that
                  effective communication is the cornerstone of bringing dream
                  projects to life, ensuring that our clients' visions are not
                  just realized but are enhanced by our technical expertise.
                </p>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p style={{ padding: "8px 10px" }}>
                  Our strength lies in our exceptional team of experts, each of
                  whom brings a wealth of knowledge and experience to the table.
                  Their proficiency in tackling even the most complex technical
                  projects ensures that we remain at the forefront of the
                  industry.
                  <br />
                  But beyond our technical prowess, we pride ourselves on our
                  ability to foster strong, collaborative partnerships with our
                  clients. We work closely with them every step of the way,
                  ensuring their needs are seamlessly met, and their aspirations
                  are exceeded. Our focus has always been our clients' success,
                  and as they thrive, so do we.
                </p>
              </div>
              <div style={{ marginTop: "20px" }}>
                <p style={{ padding: "8px 10px" }}>
                  Join us on this journey, where your dreams meet our expertise,
                  and together, we create unparalleled digital marvels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutVideo />
      <OurOffices />
    </>
  );
};
