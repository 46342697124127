import { Link } from "react-router-dom/dist";
// import Software from '../../assets/images/Web-App.svg';
import Ai from "../../assets/images/3D.svg";
import Mobile from "../../assets/images/mob-dev.svg";
import Cloud from "../../assets/images/Cloud.svg";
import UiUx from "../../assets/images/web-dev.svg";
import Qa from "../../assets/images/Project-Takeover.svg";
import Consult from "../../assets/images/DevOps.svg";

function Services() {
  return (
    <section className="services-section">
      <div className="container">
        <div className="row">
          <div
            className="col-md-4"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <div className="box bg-blue">
              <h2>Explore Our Services</h2>
              <p>
                Utilizing state-of-the-art technology to provide dependable,
                forward-thinking solutions
              </p>
              <Link to="/contact" className="btn btn-theme">
                Learn More
              </Link>
            </div>
          </div>
          <div
            className="col-md-8"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <div className="box bg-white">
              <div className="services-list">
                <ul>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={UiUx} alt="icon" className="img-fluid" />
                      </div>
                      Custom Software Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Ai} alt="icon" className="img-fluid" />
                      </div>
                      AI & Machine Learning
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Mobile} alt="icon" className="img-fluid" />
                      </div>
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Cloud} alt="icon" className="img-fluid" />
                      </div>
                      Cloud & Infrastructure
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Cloud} alt="icon" className="img-fluid" />
                      </div>
                      Database Migration
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={UiUx} alt="icon" className="img-fluid" />
                      </div>
                      UI/UX Design
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Qa} alt="icon" className="img-fluid" />
                      </div>
                      Quality Assurance & Testing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Consult} alt="icon" className="img-fluid" />
                      </div>
                      Consulting & Strategy
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Cloud} alt="icon" className="img-fluid" />
                      </div>
                      Robotic Process Automation (RPA)
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <div className="image-icon">
                        <img src={Ai} alt="icon" className="img-fluid" />
                      </div>
                      Business Process Automation (BPA)
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
