import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function DotNet() {
    return (
        <>
            <Banner title=".NET Development" content="Backend .NET development for web apps, mobile apps, databases, portals, CRMs, and more by our team of expert .NET developers." image="dotnet"/>
            <Services name=".NET Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default DotNet;