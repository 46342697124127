import { TestimonialSlideshow } from "./TestimonialSlideShow";
import Banner from "./banner";
import Counter from "./counter";
import Intro from "./intro";
import Portfolio from "./portfolio";
import Services from "./services";
import Trusted from "./trusted";

function Home() {
    return (
       <>
       <Banner/>
       <Intro/>
       <Services/>
       <Trusted/>
       <Counter/>
       <Portfolio/>
       <TestimonialSlideshow/>
       </>
    );
}

export default Home;