import { Link } from "react-router-dom/dist";
// import Software from '../../assets/images/Web-App.svg';
import Ai from "../../assets/images/3D.svg";
import Mobile from "../../assets/images/mob-dev.svg";
import Cloud from "../../assets/images/Cloud.svg";
import UiUx from "../../assets/images/web-dev.svg";
import Qa from "../../assets/images/Project-Takeover.svg";
import Consult from "../../assets/images/DevOps.svg";

function Services(props) {
  return (
    <section className="services-section">
      <div className="container">
        <div className="row">
          <div
            className="col-md-4"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <div className="box bg-blue">
              <h2>Flexible Teams</h2>
              <p>
                We’re committed to providing quality, reliable{" "}
                <b>{props.name}</b> resources to fit your needs.
              </p>
              <Link to="/contact" className="btn btn-theme">
                Learn More
              </Link>
            </div>
          </div>
          <div
            className="col-md-8"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <div className="box bg-white">
              <div className="services-list">
                <ul>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Project-Management.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Project Management
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Team.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Team Augmentation
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Dedicated-Resource.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Dedicated Resources
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Time.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Part Time Resources
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Code-Review.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Code Review
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <div className="image-icon">
                        <img
                          src="/images/Code-File.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      Code Takeovers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
