import Banner from "./banner";
import Contact from "./contact";
import Hiring from "./hiring";
import Services from "./services";


function Php() {
    return (
        <>
            <Banner title="PHP Development" content="Backend PHP development for web and mobile apps by our team of expert PHP developers. Custom PHP development, code takeovers, and scalable development teams." image="php"/>
            <Services name="PHP Development" />
            <Hiring />
            <Contact />
        </>
    );
}

export default Php;